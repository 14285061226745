import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import logo from '../woddiniBanner.jpeg';

export default function Navbar() {
    return (
      <AppBar style={{ backgroundColor:"#17559e"}}>
        <Toolbar>
        <img src={logo} style={{height:'100px'}} alt="woddini"/>
        </Toolbar>
      </AppBar>
    );
  }