const accessories = [
    {
      "name": "30s Hollow Hold",
      "repRange": [1, 1],
      "type": ["Upper Body"],
      "equipment": [],
    }, 
    {
      "name": "60s Handstand Holds",
      "repRange": [1, 1],
      "type": ["Upper Body"],
      "equipment": [],
    },   
    {
      "name": "Cossack Squats",
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": [],
    }, 
    {
      "name": "Good Mornings",
      "weights": [75, 95],
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": [],
    }, 
    {
      "name": "30s L-sit Hold",
      "repRange": [1, 1],
      "type": ["Upper Body"],
      "equipment": [],
    },              
    {
      "name": "DB Curls",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Upper Body"],
      "equipment": ["dumbbells"],
    },
    {
      "name": "KB Swings",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Upper Body"],
      "equipment": ["kettlebell"],
    },   
    {
      "name": "KB Swings",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": ["kettlebell"],
    }, 
    {
      "name": "Turkish Getup",
      "weights": [35, 55],
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": ["kettlebell"],
    }, 
    {
      "name": "Farmer Carry",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": ["dumbbells"],
    },            
    {
      "name": "Lunges",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Lower Body"],
      "equipment": ["dumbbells"],
    },
    {
      "name": "Strict DB Press",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Upper Body"],
      "equipment": ["dumbbells"],
    },
    {
      "name": "Barbell Rows",
      "weights": [95, 135],
      "repRange": [10, 15],
      "type": ["Upper Body"],
      "equipment": ["barbell"],
    },
    {
      "name": "Skullcrushers",
      "weights": [35, 45],
      "repRange": [5, 10],
      "type": ["Upper Body"],
      "equipment": ["dumbbells"],
    },    
    {
      "name": "Chinups",
      "repRange": [5, 10],
      "type": ["Upper Body"],
      "equipment": ["pullup bar"],
    }
  ];
  
  export default accessories