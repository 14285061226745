const exercises = [
    {
      "name": "Burpees",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Double Unders",
      "repRange": [30, 50],
      "type": ["time", "emom", "amrap"],
      "equipment": ["jump rope"],
      "focus": ["cardio"],
    },
    {
      "name": "Muscle Ups",
      "repRange": [3, 5],
      "type": ["time", "emom"],
      "equipment": ["rings"],
      "focus": ["gymnastics"],
    },
    {
      "name": "Deadlift",
      "weights": [135, 225, 315],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Power Cleans",
      "weights": [95, 135, 255],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Box Jumps",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["box"],
      "focus": ["cardio"],
    },
    {
      "name": "Clean & Jerk",
      "weights": [95, 135, 255],
      "repRange": [5, 15],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Push Jerk",
      "weights": [95, 135, 255],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Handstand Push Up",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["gymnastics"],
    },
    {
      "name": "Pull Up",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["pullup bar"],
      "focus": ["cardio"],
    },
    {
      "name": "Wall Ball",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["medball"],
      "focus": ["cardio"],
    },
    {
      "name": "Sit Ups",
      "repRange": [10, 20],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Walking Lunges",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Snatch",
      "weights": [95, 135, 255],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Air Squat",
      "repRange": [15, 35],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Toes To Bar",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["pullup bar"],
      "focus": ["cardio"],
    },
    {
      "name": "Kettlebell Swing",
      "weights": [35, 45, 55],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["kettlebell"],
      "focus": ["cardio"],
    },
    {
      "name": "Overhead Squat",
      "weights": [95, 115, 135],
      "repRange": [3, 5],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Ring Dip",
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["rings"],
      "focus": ["gymnastics"],
    },
    {
      "name": "Hang Clean",
      "weights": [95, 135, 225],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Thruster",
      "weights": [95, 115, 135],
      "repRange": [5, 10],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Push Up",
      "repRange": [10, 20],
      "type": ["time", "emom", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Push Press",
      "weights": [95, 115, 135],
      "repRange": [10, 20],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Front Squat",
      "weights": [115, 135, 155, 185],
      "repRange": [10, 20],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Run 400 meters",
      "type": ["time", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Row 500 meters",
      "type": ["time", "amrap"],
      "equipment": ["rower"],
      "focus": ["cardio"],
    },
    {
      "name": "Rest 1 Minute",
      "type": ["time"],
      "equipment": ["bodyweight"],
      "focus": ["end"],
    },
    {
      "name": "Back Squat",
      "weights": [115, 135, 155, 185],
      "repRange": [10, 20],
      "type": ["time", "emom", "amrap"],
      "equipment": ["barbell"],
      "focus": ["lift"],
    },
    {
      "name": "Run 200 meters",
      "type": ["time", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["cardio"],
    },
    {
      "name": "Wall Walk",
      "repRange": [1, 3],
      "type": ["time", "amrap"],
      "equipment": ["bodyweight"],
      "focus": ["gymnastics"],
    }
  ];
  
  export default exercises